<template>
  <div class="mobile-nav-wrap">
<!--    <NuxtImg v-if="!isSearchInputExpand"
             @click="goHome({})"
             class="logo"
             format="webp"
             quality="80"
             loading="lazy"
             width="154"
             src="/logo/osn-zh-horizon.png"
             alt="logo"/>-->
    <img v-if="!isSearchInputExpand"
         @click="goHome({})"
         class="logo"
         src="@/assets/image/logo/osn-zh-horizon.png"
         alt="logo"
    />
    <div class="flex items-center justify-end w-full">
      <div class="keyword-search"
           :class="{ 'keyword-search-active': isSearchInputExpand }"
           @click="handleKeywordSearchClick">
        <input type="text"
               v-if="isSearchInputExpand"
               v-model="searchKeyword"
               :placeholder="$t('placeholder.keyword')"
               @keyup.enter="handleSpaceKeywordSearch()"
               ref="searchInput">
      </div>
      <img class="search-btn"
           src="@/assets/image/icon/menu/manage_search.svg"
           @click="handleKeywordSearchToggle()"
           alt="">
      <img @click="handleSideBar"
           class="side-bar-btn"
           src="@/assets/image/icon/menu/person.svg"
           alt="">
    </div>

  </div>
</template>
<script setup>
import {ref,nextTick} from 'vue'
import {useGoTo} from "@/composables/useGoTo";
import {useSearchWithStore} from "@/composables/useSearchWithStore";
import {useSearchQueryStore} from "@/stores/searchQuery.js";


const {go,goHome} = useGoTo()
const {handleSearchWithStore} = useSearchWithStore()
const searchQueryStore = useSearchQueryStore()
const isSearchInputExpand = ref(false);
const searchKeyword = ref('');
const searchInput = ref(null)


const props = defineProps({
  handleSideBar: {
    type: Function
  }
})


function handleKeywordSearchClick  () {

  // isSearchInputExpand.value = true;
  searchKeyword.value = ''
  nextTick(() => {
    searchInput.value.focus();
  });
}

function handleKeywordSearchToggle  () {
  isSearchInputExpand.value = !isSearchInputExpand.value;
  searchKeyword.value = ''
}

function handleSpaceKeywordSearch() {
  if (!searchKeyword.value) {
    return
  }
  searchQueryStore.initQueryDate()
  searchQueryStore.setKeyword(searchKeyword.value)
  handleSearchWithStore()
  go('/search-result')
  // searchQueryStore.clearKeyword()
}

</script>

<style scoped
       lang="scss">
.mobile-nav-wrap {
  @apply px-[20px] h-[66px] flex justify-between items-center w-full;
  img.logo {
    @apply  h-[30px];
  }

  .keyword-search {
    @apply mr-[16px] w-[24px] h-[36px] flex justify-center items-center;
    transition: width 0.2s ease-out; /* 展開的過渡動畫 */
    input {
      @apply mt-[2px] w-0 h-[34px] text-osn-grey-02 text-[16px] outline-none border-none rounded-full;
      transition: width 0.2s ease-out; /* 收合的過渡動畫 */
    }
    img {
      @apply cursor-pointer;
    }
  }

  .keyword-search-active {
    @apply mr-[16px] w-full bg-white rounded-full;
    input {
      @apply pl-[16px] w-[calc(100%-16px)] rounded-full;
    }

    img {
      @apply mr-[12px];
    }
  }

  .search-btn, .side-bar-btn {
    @apply w-[24px] h-[24px];
  }
}

</style>
