<template>
  <el-breadcrumb :separator-icon="ArrowRight"
                 class="px-4 w-[100dvw] overflow-x-auto flex whitespace-nowrap hide-scrollbar">
    <el-breadcrumb-item
        class="py-3"
        v-for="(item, index) in breads"
        :key="index"
        :to="item.path ? { path: item.path } : null"
    >
      {{ item.title }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script lang="ts"
        setup>

import {ArrowRight} from '@element-plus/icons-vue'

// 定義麵包屑項目的型別
interface BreadcrumbItem {
  title: string;
  path: string | null;
}

const selfBreadcrumb = defineModel<string>('selfBreadcrumb')

const route = useRoute()
const router = useRouter()
const breads = ref<BreadcrumbItem[]>([])


/*const breads = computed<BreadcrumbItem[]>(() => {
  const path = route.path // 使用 path 而非 fullPath
  const paths = path.split('/').filter(Boolean) // 將路徑拆分成陣列，並去掉空值

  const breadArray: BreadcrumbItem[] = []
  let currentPath = ''

  // 手動加上首頁
  breadArray.push({
    title: '空間便利店',
    path: '/'
  })

  // 遍歷每一層路徑，生成麵包屑
  paths.forEach(pathSegment => {
    currentPath += `/${pathSegment}`
    const matchedRoute = router.resolve(currentPath).matched[0]
    console.log(matchedRoute);
    if (matchedRoute) {
      breadArray.push({
        title: matchedRoute.meta.title || matchedRoute.name,
        path: currentPath
      })
    }
  })

  return breadArray
})*/
// 計算麵包屑的方法
const updateBreadcrumbs = () => {
  const path = route.path;

  // 如果當前路徑是首頁，就清空麵包屑
  if (path === '/') {
    breads.value = [];
    return;
  }

  const breadArray: BreadcrumbItem[] = [];

  // 手動加入首頁
  breadArray.push({
    title: '空間便利店',
    path: '/'
  });

  // 遍歷 route.matched 確保包含子路由
  route.matched.forEach((matchedRoute) => {
    if (!matchedRoute.meta.title && !matchedRoute.name) return;

    let title: string;
    // 如果是文章詳情或空間詳情頁，使用動態標題
    title = ((['article-detail', 'space-detail'].includes(matchedRoute.meta.name)
        ? selfBreadcrumb.value
        : matchedRoute.meta.title) || matchedRoute.name || '') as string;

    let resolvedPath = matchedRoute.path;
    Object.entries(route.params).forEach(([paramKey, paramValue]) => {
      resolvedPath = resolvedPath.replace(`:${paramKey}()`, String(paramValue)); // 修正這裡的替換
      resolvedPath = resolvedPath.replace(`:${paramKey}`, String(paramValue)); // 確保即使沒有 `()` 也能替換
    });
    resolvedPath = resolvedPath.replace(/\(\)/g, ''); // 移除可能多出來的 ()

    /*breadArray.push({
      title,
      path: matchedRoute.path.startsWith('/')
          ? matchedRoute.path
          : `/${matchedRoute.path}` // 確保 `path` 是絕對路徑
    });*/
    breadArray.push({
      title,
      path: resolvedPath
    });
  });

  breads.value = breadArray;
};

// 監聽路由和事件總線的變化
watch([() => route.matched], () => {
  updateBreadcrumbs()
}, {immediate: true})
</script>

<style scoped>

/* 隱藏滾動條 */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>
